import { PageProps, graphql } from "gatsby"
import { LinkItem, SubtleLinkItem } from "../components/linkItem"
import { format } from "date-fns"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import React from "react"

const Connector = styled.div`
  flex-grow: 1;
  height: calc(100% - 8px);
  background-position: bottom;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    black 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 10px 1px;
  margin: 0px 10px 5px;
  opacity: 0.7;
`

const PuzzleName = styled.span`
  font-weight: 700;
  white-space: nowrap;
`

const PuzzleDate = styled.span`
  opacity: 0.7;
  white-space: nowrap;
`

const ExternalLink: React.ElementType<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = LinkItem.withComponent("a")

ExternalLink.defaultProps = { target: "_blank" }

export default function Puzzles(
  props: PageProps<{
    allPuzzle: {
      nodes: Array<{
        name: string
        date: string
        slug: string
        externalUrl: string | null
      }>
    }
  }>
) {
  return (
    <Layout>
      <Helmet>
        <title>Puzzles</title>
      </Helmet>
      <h1>Puzzles</h1>
      {props.data.allPuzzle.nodes.map(puzzle => {
        const formattedDate = format(new Date(puzzle.date), "MMMM do, y")
        const children = (
          <>
            <PuzzleName>{puzzle.name}</PuzzleName>
            <Connector />
            <PuzzleDate>{formattedDate}</PuzzleDate>
          </>
        )
        return puzzle.externalUrl ? (
          <ExternalLink href={puzzle.externalUrl}>{children}</ExternalLink>
        ) : (
          <LinkItem to={`/${puzzle.slug}`}>{children}</LinkItem>
        )
      })}
      <SubtleLinkItem to="/">Front page</SubtleLinkItem>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PuzzlesQuery {
    allPuzzle(sort: { order: DESC, fields: date }) {
      nodes {
        name
        date
        slug
        externalUrl
      }
    }
  }
`
